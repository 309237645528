

import {anchorLinkScroll, isInViewport, fadeIn} from './modules/helpers.js';
import Popup from './modules/popup-window.js';

// Take some useful functions
import {
	closest_polyfill,
} from './modules/helpers.js';

// Tabs functionality (uncomment it if you need it)
import { tabsNavigation } from './modules/navi-tabs';



/**
 * All custom code is wrapped in IIFE function
 * to prevent affect our code to another parts of code
 */
;(function ( $ ) {

	/**
	 * PLease Collect here all variables with DOM elements
	 * Use const for all DOM elements and type it as UPPERCASE text
	 * It will help to each developer understand that it's a const not a variable
	 */

	const popupInstance = new Popup();
	popupInstance.init();


	//const EXAMPLE = document.querySelector('.js-preloader-wrapper');
	const hamburger = document.querySelector('.js-hamburger');
	const closeMenu = document.querySelector('.js-close-menu');
	const scrollTop = document.querySelector('.js-scroll-top');
	const eventListSlider = document.querySelector('.js-event-list-slider');

	const animateBlock = document.querySelectorAll( '.js-is-animate' );
	const partners = document.querySelector('.js-partners-list');
	const body = document.querySelector('body');
	const citySwiper1 = document.querySelectorAll('.city-swipper');
	const discountBusines = document.querySelectorAll('.js-discount-busines');
	const popularBusines = document.querySelector('.js-discount-popular');
	const loadEvents = document.querySelector('.js-load-events');
	const loadCourses = document.querySelector('.js-load-courses');
	const loadDiscounts = document.querySelector('.js-load-discounts');
	const loadDiscountTag = document.querySelector('.js-load-discount-tag');
	const register_user = document.querySelector('#register-user');
	const userAccount = document.querySelector('#user-account');
	const termsCheckbox = document.querySelectorAll('.js-privacy_policy');
	const mobileMenu = document.querySelector('.js-mobile-menu');
	const coursesMobileList = document.querySelector('.js-courses-mobile-list');
	const anchorLinks = document.querySelectorAll('.anchor > a');
	const lostPasswordForm = document.querySelector('.js-lost-password');
	const changePasswordForm = document.querySelector('.js-change-password');

	function setCookie(name, value, hours) {
		var date = new Date();
		date.setTime(date.getTime() + (hours * 60 * 60 * 1000)); // Устанавливаем срок в миллисекундах
		var expires = "expires=" + date.toUTCString();
		document.cookie = name + "=" + value + "; " + expires + "; path=/";
	}

	function deleteCookie(name) {
		document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
	}


	changePasswordForm && changePasswordForm.addEventListener('submit', event => {
		event.preventDefault();

		const data = new FormData(event.target);
		data.append( 'action', 'ajax_set_password' );

		fetch(var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			})
			.then((response) => response.json())
			.then((dataResponse) => {
				const error = document.querySelector('.js-lost-pass-error');

				if(dataResponse.success) {
					error.innerText = dataResponse.data;
					deleteCookie('password_user_id');
				} else {
					error.innerText = dataResponse.data;
				}
			})
	})

	lostPasswordForm && lostPasswordForm.addEventListener('submit', event => {
		event.preventDefault();

		const data = new FormData(event.target);
		data.append( 'action', 'ajax_lost_password' );

		fetch(var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			})
			.then((response) => response.json())
			.then((dataResponse) => {
				const error = document.querySelector('.js-lost-pass-error');

				if(dataResponse.success) {
					window.location = dataResponse.data.url;
					error.innerText = '';
					setCookie('password_user_id',  dataResponse.data.user_id, 2);
				} else {
					error.innerText = dataResponse.data;
					deleteCookie('password_user_id');
				}

			})
	})

	anchorLinks && anchorLinks.forEach( item => {
		item.addEventListener('click', event => {
			event.preventDefault();
			body.classList.remove('popup-opened');
			const siteUrl = var_from_php.site_url+'/';
			const currentURL = window.location.href;
			const link = event.target.getAttribute('href');

			mobileMenu && mobileMenu.classList.remove('open');

			if ( siteUrl == currentURL) {
				const ANCHOR_ELEMENT = document.querySelector( link );

				(ANCHOR_ELEMENT) && window.scroll( {
					'behavior': 'smooth',
					'left': 0,
					'top': ANCHOR_ELEMENT.offsetTop
				} );
			} else {
				window.location.href = siteUrl+link;
			}


		})
	})

	$('.custom-select').select2({
		minimumResultsForSearch: -1,
	});

	setTimeout(()=>{
		const paymentReminderPopup = document.querySelector('#payment_reminder');
		const hideReminderValue = getCookie('hide-reminder');

		if(!hideReminderValue || hideReminderValue=='false') {
			if ( paymentReminderPopup ) {
				fadeIn(paymentReminderPopup);
			}
		}

	}, 5000)

	function getCookie(name) {
		const cookies = document.cookie.split(';');

		for(let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i].trim();

			if(cookie.startsWith(name + '=')) {

				return cookie.substring(name.length + 1);
			}
		}

		return false;
	}

	setTimeout(() => {
		const hideReminderValue = getCookie('hide-become');
		const popup = document.querySelector('#become-team');
		if (!popup) return;

		if(!hideReminderValue || hideReminderValue=='false') {
			fadeIn(popup);
		}

	}, 4000);


	userAccount && userAccount.addEventListener('submit', event => {
		event.preventDefault();
		const form = event.target;
		const userId = form.dataset.user;
		const user_formats = form.querySelector('select[name="formats"]');
		const user_interests = form.querySelector('select[name="interests"]');
		const formatsVal = $(user_formats).val();
		const userInterests = $(user_interests).val();
		const successMess = form.querySelector('.js-success-mess');


		const data = new FormData(form);
		data.append( 'action', 'ajax_register_user' );
		data.append( 'user_id', userId );
		data.append( 'user_formats', formatsVal );
		data.append( 'user_interests', userInterests );

		fetch(var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			})
			.then((response) => response.json())
			.then((data) => {

				successMess && successMess.classList.add('active');

				setTimeout(() => {
					successMess && successMess.classList.remove('active');
				}, 2000);

			})

	})

	termsCheckbox && termsCheckbox.forEach(item => {
		item.addEventListener('change', event => {
			event.preventDefault();
			const state = event.target.checked;
			const parent = event.target.closest('form');
			const button = parent.querySelector('.submit-button');

			if(!button) return;

			if (!state ) {
				button.disabled = true;
			} else {
				button.disabled = false;
			}
		})
	})

	register_user && register_user.addEventListener('submit', event => {
		event.preventDefault();
		const form = event.target;

		const data = new FormData(form);
		data.append( 'action', 'check_and_register_user' );

		fetch(var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			})
			.then((response) => response.json())
			.then((data) => {
				if(!data.data.login) {
					const errorText = data.data.error;
				} else {
					window.location.href = data.data.url;
				}
				console.log('data', data);
			})

	})

	loadDiscountTag && loadDiscountTag.addEventListener('click', event => {
		event.preventDefault();

		const offset = event.target.dataset.offset;
		const term = event.target.dataset.term;
		const data   = new FormData();
		data.append( 'action', 'ajax_load_discount_tag' );
		data.append( 'offset', offset );
		data.append( 'term', term );

		fetch(var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			})
			.then((response) => response.json())
			.then((data) => {
				if ( data.success) {
					const html = data.data.content;
					const discountWrap = document.querySelector('.js-discount-list');

					if(discountWrap && html) {
						discountWrap.insertAdjacentHTML('beforeend', html);
						const newOffset = parseInt(offset) + 12;
						event.target.dataset.offset = newOffset;
					}

					if(!html) {
						event.target.disabled = true;
					}
				}
			})

	})

	loadDiscounts && loadDiscounts.addEventListener('click', event => {
		event.preventDefault();

		const terms = event.target.dataset.terms;
		const data   = new FormData();
		data.append( 'action', 'ajax_load_discounts' );
		data.append( 'terms', terms );

		fetch(var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			})
			.then((response) => response.json())
			.then((data) => {
				if ( data.success) {
					const html = data.data.content;
					const findTerms = data.data.terms;
					const discountsWrap = document.querySelector('.js-discounts-wrap');


					if(discountsWrap && html) {
						$('.js-discount-busines').slick('unslick');
						discountsWrap.insertAdjacentHTML('beforeend', html);
						discount_slider();
						if(findTerms) {
							event.target.dataset.terms = `${terms},${findTerms}`;
						}
					}

					if(!html) {
						event.target.disabled = true;
					}
				}
			})

	})

	loadCourses && loadCourses.addEventListener('click', event => {
		event.preventDefault();

		const offset = event.target.dataset.offset;
		const data   = new FormData();
		data.append( 'action', 'ajax_load_courses' );
		data.append( 'offset', offset );

		fetch(var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			})
			.then((response) => response.json())
			.then((data) => {
				if ( data.success) {
					const html = data.data.content;
					const coursesWrap = document.querySelector('.js-courses-list');

					if(coursesWrap && html) {
						coursesWrap.insertAdjacentHTML('beforeend', html);
						const newOffset = parseInt(offset) + 12;
						event.target.dataset.offset = newOffset;
					}

					if(!html) {
						event.target.disabled = true;
					}
				}
			})

	})

	loadEvents && loadEvents.addEventListener('click', event => {
		event.preventDefault();
		const offset = event.target.dataset.offset;

		const data = new FormData();
		data.append( 'action', 'ajax_load_events' );
		data.append( 'offset', offset );

		fetch(var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			})
			.then((response) => response.json())
			.then((data) => {
				if ( data.success) {
					const html = data.data.content;
					const eventsWrap = document.querySelector('.js-event-list');

					if(eventsWrap && html) {
						eventsWrap.insertAdjacentHTML('beforeend', html);
						const newOffset = parseInt(offset) + 12;
						event.target.dataset.offset = newOffset;
					}

					if(!html) {
						event.target.disabled = true;
					}
				}
			})


	})

	if(popularBusines) {
		$('.js-discount-popular').slick({
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: 4,
			autoplay: true,
			autoplaySpeed: 4000,
			responsive: [
				{
					breakpoint: 993,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
					}
				},
				{
					breakpoint: 769,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
					}
				},
				{
					breakpoint: 481,
					settings: {
						centerMode: true,
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});
	}

	function discount_slider () {
		$('.js-discount-busines').slick({
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: 4,
			autoplay: true,
			autoplaySpeed: 2000,
			responsive: [
				{
					breakpoint: 993,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
					}
				},
				{
					breakpoint: 769,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
					}
				},
				{
					breakpoint: 481,
					settings: {
						centerMode: true,
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});
	}

	if (discountBusines) {
		discount_slider();
	}

	if(partners) {
		$(partners).slick({
			dots: false,
			infinite: true,
			speed: 600,
			slidesToShow: 5,
			slidesToScroll: 1,
			//variableWidth: true,
			centerMode: true,
			autoplay: true,
			autoplaySpeed: 1500,
			arrows: false,
			responsive: [
				{
					breakpoint: 1201,
					settings: {
						slidesToShow: 5,
						slidesToScroll: 1,
					}
				},
				{
					breakpoint: 769,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 481,
					settings: {
						centerMode: true,
						slidesToShow: 2,
						slidesToScroll: 1
					}
				}
			]
		});
	}

	if ( citySwiper1 ) {
		$('.city-swipper').slick({
			dots: false,
			infinite: true,
			speed: 600,
			slidesToShow: 4,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 1000,
			arrows: true,
			responsive: [
				{
					breakpoint: 1201,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
					}
				},
				{
					breakpoint: 769,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
					}
				},
				{
					breakpoint: 481,
					settings: {
						centerMode: true,
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});
	}

	if(eventListSlider) {
		$(eventListSlider).slick({
			dots: false,
			infinite: true,
			speed: 600,
			slidesToShow: 1,
			slidesToScroll: 1,
			variableWidth: true,
			centerMode: true,
			autoplay: true,
			autoplaySpeed: 1000,
			arrows: false
		});
	}

	if ( coursesMobileList ) {
		$(coursesMobileList).slick({
			dots: false,
			infinite: true,
			speed: 600,
			slidesToShow: 1,
			slidesToScroll: 1,
			variableWidth: true,
			centerMode: true,
			autoplay: true,
			autoplaySpeed: 1000,
			arrows: false
		});
	}


	animateBlock && [...animateBlock].forEach( ( item ) => {
		if ( isInViewport( item, 10 ) ) {
			item.classList.add('animate');
		}
	} );

	/**
	 * Scroll handler
	 */
	window.addEventListener( 'scroll', ( event ) => {
		animateBlock && [...animateBlock].forEach( ( item ) => {
			if ( isInViewport( item, 100 ) ) {
				item.classList.add('animate');
			} else {
				item.classList.remove('animate');
			}
		} );

	} );


	scrollTop && scrollTop.addEventListener('click', event => {
		event.preventDefault();
		window.scroll( {
			'behavior': 'smooth',
			'left': 0,
			'top': 0
		} );
	})

	hamburger && hamburger.addEventListener('click', (event) => {
		event.preventDefault();
		mobileMenu && mobileMenu.classList.add('open');
		body.classList.add('popup-opened');
	})

	closeMenu && closeMenu.addEventListener('click', (event) => {
		event.preventDefault();
		mobileMenu && mobileMenu.classList.remove('open');
		body.classList.remove('popup-opened');
	})


	/**
	 * Occurs when all HTML has been fully loaded and passed by the parser,
	 * without waiting for the stylesheets, images and frames to finish loading
	 */
	document.addEventListener("DOMContentLoaded", function(event) {
		const loginForm = document.querySelector('#loginform');


		loginForm && loginForm.addEventListener('submit', event => {
			event.preventDefault();

			const data = new FormData(event.target);
			data.append( 'action', 'check_and_login_user' );

			fetch(var_from_php.ajax_url,
				{
					method: "POST",
					credentials: 'same-origin',
					body: data
				})
				.then((response) => response.json())
				.then((responseData) => {
					const errorText = event.target.querySelector('.js-login-error');

					if(responseData.success) {
						window.location.reload();
						errorText && errorText.classList.remove('active');
					} else {
						errorText && errorText.classList.add('active');
					}
				})

		})



		// Init Closest polyfill method ( Don't delete it )
		closest_polyfill();

		// Init Popup Windows ( use it if you need Popup functionality )
		//const popup_instance = new Popup();
		//popup_instance.init();


		/**
		 * Add global handler for click event
		 * The main idea - to improve site performance
		 * We added only 1 event handler to "click" event
		 * and then use date-role attribute on each( needed ) elements
		 * to define on which element event was executed..
		 */
		document.body.addEventListener( 'click', event => {
			const ROLE = event.target.dataset.role;
			const clickElem = event.target;

			if ( !ROLE ) return;

			switch ( ROLE ) {

				// Scroll page to top
				case 'scroll-bottom':
				{
					event.preventDefault();
					const banner = clickElem.closest('.js-banner');
					if(!banner) return;
					const heightBanner = banner.clientHeight;
					window.scroll( {
						'behavior': 'smooth',
						'left': 0,
						'top': heightBanner
					} );

				}
					break;


				case 'payment-button':
				{
					event.preventDefault();
					const publicKey = clickElem.dataset.key;
					if ( !publicKey ) return;
					const stripe = Stripe( publicKey );
					const apiUrl = var_from_php.site_url + '/wp-json/events/v1/stripe_request/';
					const data = new FormData();
					fetch( apiUrl,
						{
							method: "POST",
							credentials: 'same-origin',
							body: data
						} )
						.then( ( response ) => response.json() )
						.then( ( sessionId ) => {
							return stripe.redirectToCheckout( {sessionId: sessionId} );
						} )
					
					
				}
					break;
			}
		} );


		document.body.addEventListener( 'change', event => {
			const ROLE = event.target.dataset.role;
			const clickElem = event.target;

			if ( !ROLE ) return;

			switch ( ROLE ) {

				// Scroll page to top
				case 'hide-reminder':
				{
					event.preventDefault();
					const checked = event.target.checked;
					const currentDate = new Date();
					currentDate.setDate(currentDate.getDate() + 5);
					const expirationDate = currentDate.toUTCString();
					document.cookie = `hide-reminder=${checked}; expires=${expirationDate}; path=/`;
				}
					break;

				case 'non-show-popup':
				{
					event.preventDefault();
					const checked = event.target.checked;
					const currentDate = new Date();
					currentDate.setDate(currentDate.getDate() + 1);
					const expirationDate = currentDate.toUTCString();
					document.cookie = `hide-become=${checked}; expires=${expirationDate}; path=/`;
				}
					break;
			}
		} );

	});


	// Use it when you need to know that everything is loaded (html, styles, images)
	window.addEventListener('load', (event) => {

		/**
		 * Simple hack for some cases
		 */
		setTimeout( () => {
			//document.body.classList.add( 'loaded' );
		}, 500 );


	});

})( jQuery );
